<script setup lang="ts">
import { isDesktop } from "~/utils/platform"

const { onAppMounted } = useNavigation()

useHead({
  bodyAttrs: {
    class: () => `bg-auth ${isDesktop() ? "desktop" : "browser"}`,
  },
  titleTemplate: (titleChunk) => {
    return `${titleChunk} | Poly: Intelligent Cloud Storage`
  },
})

onMounted(async () => onAppMounted())
</script>

<template>
  <native-drag-region />
  <div class="flex">
    <div class="h-screen w-1/2 min-w-screen-sm p-4">
      <slot />
    </div>
    <div
      id="auth-side-view"
      class="h-screen w-1/2"
    >
      <div class="h-screen w-full overflow-hidden pl-40 pt-40">
        <img
          id="auth-side-view-image"
          src="~/assets/images/onboarding/library.webp"
          class="animate- ml-0 h-full w-full rounded-tl-xl bg-background-window object-cover backdrop-blur-lg backdrop-filter"
          :style="{ objectPosition: 'top left' }"
        >
      </div>
    </div>
  </div>
</template>

<style lang="sass">
@import '~/assets/styles/generated/variables.sass'
#auth-side-view
  background-image: url('~/assets/images/onboarding/bg-abstract.jpg')
  background-size: cover
#auth-side-view-image
  animation: zoom-in 60s ease-out infinite
  transform-origin: 300px 200px
@keyframes zoom-in
  from
    transform: scale(1) translate3d(0px, 0px, 0px)
  50%
    transform: scale(1.2) translate3d(0px,0px,0px)
  to
    transform: scale(1) translate3d(0px, 0px, 0px)

.bg-auth
  @apply text-base overflow-hidden t-[#{$global-t-default}]
  &.browser
    @apply bg-[#{$page-bg-primary}]
  &.desktop
    @apply bg-[#{$page-bg-primary-desktop}]
</style>
